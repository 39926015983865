const singleValues = document.querySelectorAll('.section-values__single-value');
function handleIntersection(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.classList.add('active');
        } else {
            entry.target.classList.remove('active');
        }
    });
}
const observer = new IntersectionObserver(handleIntersection, {
    root: null, // Use the viewport as the root
    rootMargin: '0px', // No margin
    threshold: 0.7, // 50% of the element is visible
});

singleValues.forEach(singleValue => {
    observer.observe(singleValue);
});



const valuesSections = document.querySelectorAll(".section-values");

if (valuesSections && valuesSections.length > 0) {

    valuesSections.forEach(section => {
        const showMoreButton = section.querySelector(".js-show-values");
        const valuesList = section.querySelector(".section-values__values-list");
        if (showMoreButton && valuesList) {
            showMoreButton.addEventListener("click", () => {
                valuesList.classList.add("-show-mobile");
                showMoreButton.classList.add("-hide");
            })
        }
    });
}